.x-modal
  position: fixed
  top: 0
  left: 0
  right: 0
  bottom: 0
  padding: 24px
  z-index: 10020
  background-color: rgba(0, 0, 0, .75)
  white-space: nowrap
  text-align: center
  overflow: auto
  &:before
    content: ""
    display: inline-block
    vertical-align: middle
    height: 100%
  &__content
    display: inline-block
    vertical-align: middle
    white-space: normal
    text-align: left
    position: relative
  &__close
    position: absolute
    top: 24px
    right: 24px
    width: 16px
    height: 16px
    border: none
    background: none
    font-size: 0
    line-height: 0
    color: var(--icon-dark-50)
    &:hover
      color: var(--icon-primary)
