import { Component, Host, Prop, Event, EventEmitter, Element, h } from '@stencil/core';

@Component({
  tag: 'x-modal',
  styleUrl: 'x-modal.sass',
})
export class XModal {
  @Element() el: HTMLElement;

  @Prop() width: string;
  @Prop() autoremove: boolean = false;
  @Prop({ mutable: true, reflect: true }) open: boolean = false;

  @Event() load: EventEmitter;
  @Event() close: EventEmitter;

  private closeModal = () => {
    this.open = false;
    this.close.emit();

    if (this.autoremove) {
      this.el.parentNode.removeChild(this.el);
    }
  };

  private handleClose = () => {
    this.closeModal();
  };

  private handleOverlayClick = e => {
    if (e.target === e.currentTarget) {
      this.closeModal();
    }
  };

  private handleKeyUp = e => {
    const keyCode = e.keyCode || e.charCode;

    if (this.open && keyCode === 27) {
      this.closeModal();
    }
  };

  componentDidLoad() {
    if (this.open) {
      this.load.emit();
    }
  }

  componentDidUpdate() {
    if (this.open) {
      this.load.emit();
      window.addEventListener('keyup', this.handleKeyUp);
    } else {
      window.removeEventListener('keyup', this.handleKeyUp);
    }
  }

  render() {
    return (
      <Host class="x-modal" hidden={!this.open} onClick={this.handleOverlayClick}>
        <div class="x-modal__content" style={{ width: this.width }}>
          <button type="button" class="x-modal__close" onClick={this.handleClose}>
            <x-icon glyph="modal-cross"></x-icon>
          </button>
          <slot></slot>
          <div class="air-datepicker-global-container"></div>
        </div>
      </Host>
    );
  }
}
